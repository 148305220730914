import React, { useState } from 'react';
import { Typography, Space  } from 'antd';

const { Title, Paragraph } = Typography;

const Bottom = () => {
    return (
        <div>
            <Space direction="horizontal" align="left" style={{width: '100%'}}>
  <span>Hello
            <Title level={3}>What is a cron expression?</Title>
            <Paragraph style={{alignItems: 'left', justifyContent: true, padding: '0 100px'}}>
            A cron expression allows you to set up a detailed and specific recurring schedule, enabling tasks to run at precise times you select. The EventBridge Scheduler facilitates the configuration of these cron-based schedules either in Universal Coordinated Time (UTC) or in any time zone you choose at the time of schedule creation. This type of scheduling offers greater control over the timing and frequency of your tasks. Opt for cron-based schedules when your needs for recurring tasks are not met by the standard rate expressions offered by EventBridge Scheduler. An example of a cron-based schedule could be setting a task to execute at 8:00 a.m. PST on the first Monday of each month. Setting up such a schedule involves using a cron expression.
            <br/>
            <br/>
A cron expression is structured with six fields, the first five being mandatory: minutes, hours, day-of-month, month, and day-of-week, followed by an optional sixth field for the year. These fields are separated by white space as illustrated in the following example.
            <br/>
            </Paragraph>
            <Title level={3}>Wildcard Usage</Title>
            <Paragraph>
<ul>
<li>The , (comma) wildcard allows for the inclusion of multiple values. For instance, using JAN,FEB,MAR in the Month field signifies January, February, and March.
</li><li>
The - (dash) wildcard is used for indicating a range of values. For example, in the Day field, 1-15 means every day from the 1st to the 15th of that month.
</li><li>
The * (asterisk) wildcard represents all possible values in a field. In the Hours field, * means every hour of the day. However, it's important to note that * cannot be used simultaneously in both the Day-of-month and Day-of-week fields. If used in one, ? must be used in the other.
</li><li>
The / (slash) wildcard is for specifying increments. For instance, entering 1/10 in the Minutes field sets the schedule for every tenth minute, beginning from the first minute of the hour (like the 11th, 21st, 31st minute, etc.).
</li><li>
The ? (question mark) wildcard denotes an unspecified value, used when any value is acceptable. For example, if specifying 7 in the Day-of-month field, and any day of the week is acceptable, ? can be used in the Day-of-week field.
</li><li>
The L wildcard, when used in the Day-of-month or Day-of-week fields, indicates the last day of the month or week.
</li><li>
The W wildcard in the Day-of-month field targets the nearest weekday. For example, 3W in the Day-of-month field would represent the nearest weekday to the third day of the month.
</li><li>
The # wildcard in the Day-of-week field is for specifying a particular occurrence of a day within a month. For example, 3#2 denotes the second Tuesday of the month (3 for Tuesday, being the third day of the week, and 2 for the second occurrence in the month).
          </li>  </ul></Paragraph>
          </span>
</Space>
        </div>
    )
};

export default Bottom;