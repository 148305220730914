import React, { useState } from 'react';
import { Typography,  } from 'antd';

const { Title, Paragraph } = Typography;

const Banner = () => {
    return (
        <div>
            <Title>CronStack - A Cron Job Generator for AWS Scheduler</Title>
            <Paragraph>
                This app provides easy to configure method to create AWS Schedulers. 
            </Paragraph>
        </div>
    )
};

export default Banner;