import { Flex, Typography, Button, Tooltip, Space } from 'antd';

import { Layout, theme, Menu, Breadcrumb } from "antd";
import { Col, Divider, Row } from 'antd';

import { CopyOutlined } from '@ant-design/icons';
import Minutes from "./components/Minutes";
import Hours from './components/Hours';
import DayOfMonth from './components/Day';
import Month from './components/Month';
import Year from './components/Year';
import { useEffect, useState } from 'react';

const { Title, Text,Paragraph } = Typography;
const { Header, Content, Footer, Sider } = Layout;

const Cron = () => {
    const [startMinutes, setStartMinutes] = useState(1);
    const [freqMinutes, setFreqMinutes] = useState(0);
    const [startHours, setStartHours] = useState(1);
    const [freqHours, setFreqHours] = useState(0);
    const [daysOfMonth, setDaysOfMonth] = useState('?');
    const [daysOfWeek, setDaysOfWeek] = useState('*');
    const [months, setMonths] = useState('*');
    const [years, setYears] = useState('*');
    const [isDaysOfMonth, setIsDaysOfMonth] = useState(false);
    const [cron, setCron] = useState('');

    const onMinutesStartChange = (value) => {
        // console.log('minute start: ', value);
        setStartMinutes(value);
    };

    const onMinutesFreqChange = (value) => {
        // console.log('minute freq: ', value);
        setFreqMinutes(value);
    };

    const onHoursStartChange = (value) => {
        // console.log('hours start: ', value);
        setStartHours(value);
    };

    const onHoursFreqChange = (value) => {
        // console.log('hours freq: ', value);
        setFreqHours(value);
    };

    const onDaysOfMonthChange = ({ target: { value } }) => {
        // console.log(value);
        setDaysOfMonth(value);
        setIsDaysOfMonth(true);
    };

    const onDaysOfWeekChange = (value) => {
        const val = value.reduce((acc, cur) => `${acc},${cur}`);
        // console.log(val);
        setDaysOfWeek(val);
        setIsDaysOfMonth(false);
    };

    const onMonthsChange = (value) => {
        const val = value.reduce((acc, cur) => `${acc},${cur}`);
        // console.log(val);
        setMonths(val);
    };

    const onYearsChange = ({ target: { value } }) => {
        // console.log(value);
        setYears(value);
    };

    useEffect(() => {
        const cronDef = `cron(${startMinutes}/${freqMinutes} ${startHours}/${freqHours} ${(isDaysOfMonth ? daysOfMonth : '?')} ${months} ${(isDaysOfMonth) ? '?' : daysOfWeek} ${years})`;
        setCron(cronDef);
    }, [startMinutes, freqMinutes, startHours, freqHours, daysOfMonth, daysOfWeek, months, years, isDaysOfMonth]);

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Layout>
            <br />
            <Content style={{ padding: '0 48px', }}>
                <Layout style={{ padding: '24px 0', background: colorBgContainer, borderRadius: borderRadiusLG }}>
                    <Content style={{ padding: '0px 10px 20px 10px' }}>
                        <Title>cron</Title>
                        <Paragraph code copyable style={{ fontSize: 20 }}>{cron}</Paragraph>
                    </Content>
                </Layout>
                <br />
                <Layout style={{ padding: '24px 0', background: colorBgContainer, borderRadius: borderRadiusLG }}>
                    <Content style={{ padding: '0px 10px 20px 10px' }}>
                    <Row>
      <Col xs={5} sm={5} md={5} lg={5} xl={5}>
        <Minutes
          start={startMinutes}
          freq={freqMinutes}
          onStartChange={onMinutesStartChange}
          onFreqChange={onMinutesFreqChange}
        />
      </Col>
      <Col xs={5} sm={5} md={5} lg={5} xl={5}>
        <Hours
          start={startHours}
          freq={freqHours}
          onStartChange={onHoursStartChange}
          onFreqChange={onHoursFreqChange}
        />
      </Col>
      <Col xs={5} sm={5} md={5} lg={5} xl={5}>
        <DayOfMonth
          onDaysOfWeekChange={onDaysOfWeekChange}
          onDaysOfMonthChange={onDaysOfMonthChange}
        />
      </Col>
      <Col xs={5} sm={5} md={5} lg={5} xl={5}>
        <Month onChange={onMonthsChange} />
      </Col>
      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
        <Year onChange={onYearsChange} />
      </Col>
    </Row>
                    </Content>
                </Layout>
            </Content>
        </Layout>
    );
};

export default Cron;
