import React, { useState } from 'react';
import { Typography, InputNumber } from 'antd';

const { Title } = Typography;

const Minutes = (props) => {
  return (
    <div>
      <Title>Hours</Title>
      Start at:
      <> </>
      <InputNumber min={0} max={59} value={props.start} size={'large'} onChange={props.onStartChange}/>
      <> </>
      and run every
      <> </>
      <InputNumber min={0} max={59} value={props.freq} size={'large'} onChange={props.onFreqChange} /> 
      <> </>hours
    </div>
  );
};

export default Minutes;