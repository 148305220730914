import React, { useState } from 'react';
import { Typography, InputNumber, Input, Tabs, Checkbox } from 'antd';

const { Title } = Typography;

const Year = (props) => {

  return (
    <div>
    <Title>Year</Title>
      Add a comma separated list of years, * for all.
      <p/>
      <Input
        placeholder='2024,2025'
        size={'large'}
        maxLength={30}
        onChange={props.onChange} />
    </div>
  )
};

export default Year;