import { Layout, theme, Menu, Breadcrumb, Typography } from "antd";
import Cron from "./Cron";
import Banner from "./components/Banner";
import Bottom from "./components/Bottom";
import logo from './assets/logo.png';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const Layoutd = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
        <Layout>
            {/* <Header>
                <Text style={{fontColor: 'white'}}></Text>
            </Header> */}
            <Banner/>
            <Cron/>
            <Bottom/>
            <Footer style={{ textAlign: 'center', }}>
                Systack.com ©{new Date().getFullYear()} Created by NisalaP
            </Footer>
        </Layout>
    );
}

export default Layoutd;
