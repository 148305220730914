import React, { useState } from 'react';
import { Typography, InputNumber, Input, Tabs, Checkbox } from 'antd';

const { Title } = Typography;

const week = [
  { label: 'Monday', value: 'MON' },
  { label: 'Tuesday', value: 'TUE' },
  { label: 'Wednesday', value: 'WED' },
  { label: 'Thursday', value: 'THU' },
  { label: 'Friday', value: 'FRI' },
  { label: 'Saturday', value: 'SAT' },
  { label: 'Sunday', value: 'SUN' },
]

const DayOfMonth = (props) => {

  const dayOfWeek = () => {
    return (
      <div>
        <Checkbox.Group options={week} onChange={props.onDaysOfWeekChange} />
      </div>
    )
  };

  const dayOfMonth = () => {
    return (
      <div>
        Add a comma separated list of days, * for all days.
        <p/>
        <Input
          placeholder='1,2'
          size={'large'}
          maxLength={30}
          onChange={props.onDaysOfMonthChange} />
      </div>
    )
  };

  const options = [
    {
      label: 'Month',
      key: 'month',
      children: (dayOfMonth()),
    },
    {
      label: 'Week',
      key: 'week',
      children: (dayOfWeek())
    },
  ];

  return (
    <div>
      <Title>Days</Title>
      <Tabs
        onChange={props.onChange}
        defaultActiveKey="week"
        centered
        items={options} />
    </div>
  );
};

export default DayOfMonth;