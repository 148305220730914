import React, { useState } from 'react';
import { Typography, Checkbox } from 'antd';

const { Title } = Typography;

const month = [
    { label: 'January', value: 'JAN' },
    { label: 'February', value: 'FEB' },
    { label: 'March', value: 'MAR' },
    { label: 'April', value: 'APR' },
    { label: 'May', value: 'MAY' },
    { label: 'June', value: 'JUN' },
    { label: 'July', value: 'JUL' },
    { label: 'August', value: 'AUG' },
    { label: 'September', value: 'SEP' },
    { label: 'October', value: 'OCT' },
    { label: 'November', value: 'NOV' },
    { label: 'December', value: 'DEC' },
  ]

const Month = (props) => {
  const [months, setMonths] = useState(15);

  const onChange = (value) => {
    setMonths(value);
  };

  return (
    <div>
      <Title>Month</Title>
      <Checkbox.Group options={month} defaultValue={['January']} onChange={props.onChange} />
    </div>
  );
};

export default Month;